/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RiskDetail { 
    riskId: string;
    riskName: string;
    description: string;
    recommendation: string;
    isSuppressed: boolean;
}

