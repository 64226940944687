/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { DomainRequest } from '../model/models';
import { ResponseObject } from '../model/models';
import { ScreenTimeGroupedResponse } from '../model/models';
import { ScreenTimeRequest } from '../model/models';
import { SettingsRequest } from '../model/models';
import { SettingsResponse } from '../model/models';
import { SettingsUpdateRequest } from '../model/models';
import { UrlCheckRequest } from '../model/models';
import { UrlCheckResponse } from '../model/models';
import { UserDomain } from '../model/models';
import { UserDomainRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ChildGuardServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param urlCheckRequest 
     */
    childGuardCheckUrl(urlCheckRequest: UrlCheckRequest, extraHttpRequestParams?: any): Observable<UrlCheckResponse>;

    /**
     * 
     * 
     */
    childGuardGetDomainStatuses(extraHttpRequestParams?: any): Observable<Array<UserDomain>>;

    /**
     * 
     * 
     */
    childGuardGetScreenTime(extraHttpRequestParams?: any): Observable<ScreenTimeGroupedResponse>;

    /**
     * 
     * 
     */
    childGuardGetSettings(extraHttpRequestParams?: any): Observable<SettingsResponse>;

    /**
     * 
     * 
     */
    childGuardGetUserDomains(extraHttpRequestParams?: any): Observable<Array<UserDomain>>;

    /**
     * 
     * 
     */
    childGuardIsScreenTimeActiveNow(extraHttpRequestParams?: any): Observable<ResponseObject>;

    /**
     * 
     * 
     * @param domainRequests 
     */
    childGuardPopulateDomains(domainRequests: Array<DomainRequest>, extraHttpRequestParams?: any): Observable<ResponseObject>;

    /**
     * 
     * 
     * @param id 
     */
    childGuardRemoveScreenTime(id: string, extraHttpRequestParams?: any): Observable<ResponseObject>;

    /**
     * 
     * 
     * @param id 
     */
    childGuardRemoveUserDomain(id: string, extraHttpRequestParams?: any): Observable<ResponseObject>;

    /**
     * 
     * 
     * @param screenTimeRequests 
     */
    childGuardSaveScreenTime(screenTimeRequests: Array<ScreenTimeRequest>, extraHttpRequestParams?: any): Observable<ResponseObject>;

    /**
     * 
     * 
     * @param settingsRequest 
     */
    childGuardSaveSettings(settingsRequest: SettingsRequest, extraHttpRequestParams?: any): Observable<ResponseObject>;

    /**
     * 
     * 
     * @param userDomainRequest 
     */
    childGuardSaveUserDomain(userDomainRequest: UserDomainRequest, extraHttpRequestParams?: any): Observable<ResponseObject>;

    /**
     * 
     * 
     */
    childGuardToggleAutomaticDomainRestriction(extraHttpRequestParams?: any): Observable<ResponseObject>;

    /**
     * 
     * 
     * @param dayOfWeek 
     */
    childGuardToggleScreenTime(dayOfWeek: number, extraHttpRequestParams?: any): Observable<ResponseObject>;

    /**
     * 
     * 
     * @param settingsId 
     * @param enable 
     */
    childGuardToggleScreenTimeRestriction(settingsId: string, enable?: boolean, extraHttpRequestParams?: any): Observable<ResponseObject>;

    /**
     * 
     * 
     * @param screenTimeWindowId 
     * @param isActive 
     */
    childGuardToggleScreenTimeWindow(screenTimeWindowId: string, isActive?: boolean, extraHttpRequestParams?: any): Observable<ResponseObject>;

    /**
     * 
     * 
     */
    childGuardToggleUserDomainRestriction(extraHttpRequestParams?: any): Observable<ResponseObject>;

    /**
     * 
     * 
     * @param userDomainId 
     * @param isDeactivated 
     */
    childGuardToggleUserDomainStatus(userDomainId: string, isDeactivated?: boolean, extraHttpRequestParams?: any): Observable<ResponseObject>;

    /**
     * 
     * 
     * @param settingsUpdateRequest 
     */
    childGuardUpdatesettings(settingsUpdateRequest: SettingsUpdateRequest, extraHttpRequestParams?: any): Observable<ResponseObject>;

    /**
     * 
     * 
     * @param settingsId 
     * @param password 
     */
    childGuardValidateUser(settingsId: string, password?: string, extraHttpRequestParams?: any): Observable<ResponseObject>;

}
