import { NgModule } from '@angular/core';
// import { ApiModule, Configuration } from './generated';

@NgModule({
    imports: [
        // ApiModule.forRoot(
        //     () =>
        //         new Configuration({
        //             basePath: '',
        //         })
        // ),
    ],
    // exports: [ApiModule],
    declarations: [],
})
export class SharedNgModule { }
