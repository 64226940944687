import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountClientService, Session, User } from '@spectrum/shared-ng';
import { UtilitiesService } from '@seech/core-ng';
import { environment } from '../../../../../spectrum-web/src/environments/environment';
import { CONSTANT, SessionService } from '@seech/auth-ng';
import { ToastService } from '@seech/ux-ng';


@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent {
  authWebUrl: string = environment.AUTH_WEB_URL;
  signupRequest = {} as User;
  signupToken: string;
  returnUrl: string;
  loading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private utilitiesService: UtilitiesService,
    private sessionService: SessionService<User, Session>,
    @Inject(AccountClientService) private accountService: AccountClientService,
    @Inject(PLATFORM_ID) private platformId: object) {

    this.signupToken = this.route.snapshot.queryParams['token'];
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'home';

    if (!this.signupToken) {
      const redirectUrl = this.utilitiesService.getOrigin() + (this.returnUrl ? '&returnUrl=' + this.returnUrl : '') + '&action=signup';
      const fullAuthWebUrl = this.authWebUrl + '/login?redirect=' + encodeURIComponent(redirectUrl);

      if (isPlatformBrowser(this.platformId)) {
        window.location.href = fullAuthWebUrl;
      }
    }
  }

  ngOnInit() {
    this.signup();
  }

  signup() {
    if (this.signupToken) {
      this.loading = true;

      this.accountService.signup(this.signupRequest, { headers: { [CONSTANT.GLOBAL_TOKEN_KEY]: this.signupToken } })
        .subscribe({
          next: (response) => {
            this.sessionService.startSession(response.body!);
            this.router.navigate([this.returnUrl]).then(() => { /* this.startOnboarding() */ });
          },
          error: (error) => {
            this.toast.error('Unable to create account. Please try again.');
          },
          complete: () => {
            this.loading = false;
          }
        });
    }
  }
}
