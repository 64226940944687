/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MediumResponse } from './mediumResponse';
import { SupportedPlatformResponse } from './supportedPlatformResponse';


export interface ProductPreviewResponse { 
    id: string;
    name: string;
    key: string;
    color: string;
    logo: string;
    hint: string;
    isReleased?: boolean;
    description: string;
    supportedPlatforms: Array<SupportedPlatformResponse>;
    media: Array<MediumResponse>;
}

