/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CategoryResponse } from '../model/models';
import { PlatformResponse } from '../model/models';
import { TagResponse } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface LookupServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     */
    lookupGetCategories(extraHttpRequestParams?: any): Observable<Array<CategoryResponse>>;

    /**
     * 
     * 
     */
    lookupGetPlatforms(extraHttpRequestParams?: any): Observable<Array<PlatformResponse>>;

    /**
     * 
     * 
     */
    lookupGetTags(extraHttpRequestParams?: any): Observable<Array<TagResponse>>;

}
