/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Session } from './session';


export interface Connection { 
    id: string;
    connectionId?: string;
    sessionId: string;
    userId: string;
    isConnected: boolean;
    lastUpdated: Date;
    deletedOn?: Date;
    isDeleted?: boolean;
    deactivatedOn?: Date;
    isDeactivated?: boolean;
    session: Session;
}

