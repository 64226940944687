/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';
import { SessionToken } from './sessionToken';
import { Connection } from './connection';


export interface Session { 
    token: SessionToken;
    globalToken: SessionToken;
    id: string;
    userId: string;
    userAgent?: string;
    loginOn: Date;
    lastSeen: Date;
    lastDetectedIP?: string;
    loginStatus?: string;
    deletedOn?: Date;
    isDeleted?: boolean;
    deactivatedOn?: Date;
    isDeactivated?: boolean;
    connections: Array<Connection>;
    user: User;
}

