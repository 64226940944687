/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FileUploadContext { 
    files: string;
    name: string;
    fileName: string;
    contentType: string;
    contentDisposition: string;
}

