/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SettingsResponse { 
    id?: string;
    youngestChildDOB?: Date;
    lastUpdated: Date;
    userDomainRestrictionEnabled?: boolean;
    screenTimeRestrictionEnabled?: boolean;
    automaticDomainRestrictionEnabled?: boolean;
}

