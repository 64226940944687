export * from './account.service';
import { AccountService } from './account.service';
export * from './account.serviceInterface';
export * from './global.service';
import { GlobalService } from './global.service';
export * from './global.serviceInterface';
export * from './lookup.service';
import { LookupService } from './lookup.service';
export * from './lookup.serviceInterface';
export * from './product.service';
import { ProductService } from './product.service';
export * from './product.serviceInterface';
export * from './subscription.service';
import { SubscriptionService } from './subscription.service';
export * from './subscription.serviceInterface';
export * from './user.service';
import { UserService } from './user.service';
export * from './user.serviceInterface';
export const APIS = [AccountService, GlobalService, LookupService, ProductService, SubscriptionService, UserService];
