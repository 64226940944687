/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { RiskCheckResponse } from '../model/models';
import { RiskPreferenceRequest } from '../model/models';
import { RiskPreferenceResponse } from '../model/models';
import { SafetyReportResponse } from '../model/models';
import { UrlRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface SafeOnlineServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param request 
     */
    safeOnlineCheckUrl(request: UrlRequest, extraHttpRequestParams?: any): Observable<RiskCheckResponse>;

    /**
     * 
     * 
     */
    safeOnlineGetRiskPreferences(extraHttpRequestParams?: any): Observable<Array<RiskPreferenceResponse>>;

    /**
     * 
     * 
     * @param request 
     */
    safeOnlineGetSafetyReport(request: UrlRequest, extraHttpRequestParams?: any): Observable<SafetyReportResponse>;

    /**
     * 
     * 
     * @param request 
     */
    safeOnlineSetRiskPreferences(request: RiskPreferenceRequest, extraHttpRequestParams?: any): Observable<Blob>;

}
