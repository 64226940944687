/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Coordinate } from './coordinate';


export interface Envelope { 
    isNull: boolean;
    width: number;
    height: number;
    diameter: number;
    minX: number;
    maxX: number;
    minY: number;
    maxY: number;
    area: number;
    minExtent: number;
    maxExtent: number;
    centre?: Coordinate;
}

