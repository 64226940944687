/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ProductDetailResponse } from '../model/models';
import { ProductFeedbackRequest } from '../model/models';
import { ProductPreviewResponse } from '../model/models';
import { ProductResponse } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ProductServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param productId 
     * @param request 
     */
    productAddFeedback(productId: string, request: ProductFeedbackRequest, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param productId 
     */
    productExpressInterest(productId: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param productId 
     * @param platformId 
     */
    productExpressInterestPerPlatform(productId: string, platformId: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param productId 
     */
    productGetProductDetail(productId: string, extraHttpRequestParams?: any): Observable<ProductDetailResponse>;

    /**
     * 
     * 
     * @param productId 
     */
    productGetProductPreview(productId: string, extraHttpRequestParams?: any): Observable<ProductPreviewResponse>;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     */
    productGetProducts(query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<ProductResponse>>;

    /**
     * 
     * 
     * @param productId 
     */
    productJoinWaitlist(productId: string, extraHttpRequestParams?: any): Observable<string>;

}
