/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CoordinateEqualityComparer } from './coordinateEqualityComparer';
import { CoordinateSequenceFactory } from './coordinateSequenceFactory';
import { PrecisionModel } from './precisionModel';


export interface NtsGeometryServices { 
    geometryOverlay?: object;
    coordinateEqualityComparer?: CoordinateEqualityComparer;
    defaultSRID: number;
    defaultCoordinateSequenceFactory?: CoordinateSequenceFactory;
    defaultPrecisionModel?: PrecisionModel;
}

