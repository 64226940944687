/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RiskDetail } from './riskDetail';


export interface RiskCheckResponse { 
    safetyScore: number;
    riskLevel: string;
    warning: string;
    risks: Array<RiskDetail>;
}

