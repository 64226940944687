/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Diagnostic } from './diagnostic';


export interface Severity { 
    id: string;
    key: number;
    value: string;
    createdOn: Date;
    createdBy?: string;
    diagnostics?: Array<Diagnostic>;
}

