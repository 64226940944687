/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserSummary } from './userSummary';


export interface ReviewResponse { 
    title: string;
    comment: string;
    rating?: number;
    createdOn: Date;
    createdBy: UserSummary;
}

