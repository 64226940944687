/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeatureResponse } from './featureResponse';
import { MediumResponse } from './mediumResponse';
import { ChangeLogResponse } from './changeLogResponse';
import { SupportedPlatformResponse } from './supportedPlatformResponse';
import { ReviewResponse } from './reviewResponse';


export interface ProductDetailResponse { 
    id: string;
    name: string;
    key: string;
    color: string;
    logo: string;
    hint: string;
    isReleased?: boolean;
    description: string;
    supportedPlatforms: Array<SupportedPlatformResponse>;
    media: Array<MediumResponse>;
    rating?: number;
    ratingCount?: number;
    downloadCount: number;
    features: Array<FeatureResponse>;
    reviews: Array<ReviewResponse>;
    productTags: Array<string>;
    productCategories: Array<string>;
    changeLogs: Array<ChangeLogResponse>;
}

