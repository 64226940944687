/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FeatureFlag { 
    id: string;
    name: string;
    appId: string;
    isEnabled: boolean;
    activatedOn?: Date;
    expiredOn?: Date;
    createdOn: Date;
    createdBy: string;
    updatedOn?: Date;
    updatedBy?: string;
}

