/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Geometry } from './geometry';
import { ProductAccess } from './productAccess';
import { Subscription } from './subscription';
import { Session } from './session';


export interface User { 
    id: string;
    globalId: string;
    username: string;
    name: string;
    email: string;
    location?: string;
    geolocation?: Geometry;
    bio?: string;
    imageURL?: string;
    createdOn: Date;
    lastSeen: Date;
    deletedOn?: Date;
    isDeleted?: boolean;
    deactivatedOn?: Date;
    isDeactivated?: boolean;
    productAccesses: Array<ProductAccess>;
    sessions: Array<Session>;
    subscriptions: Array<Subscription>;
}

