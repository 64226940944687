/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ProductResponse } from '../model/models';
import { SubscriptionPlanResponse } from '../model/models';
import { UserSubscriptionResponse } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface SubscriptionServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param subscriptionKey 
     */
    subscriptionGetProductsBySubscription(subscriptionKey: string, extraHttpRequestParams?: any): Observable<Array<ProductResponse>>;

    /**
     * 
     * 
     */
    subscriptionGetSubscriptionPlans(extraHttpRequestParams?: any): Observable<Array<SubscriptionPlanResponse>>;

    /**
     * 
     * 
     * @param userId 
     */
    subscriptionGetUserSubscriptions(userId: string, extraHttpRequestParams?: any): Observable<Array<UserSubscriptionResponse>>;

}
