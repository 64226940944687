import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountService, User } from '../generated'; // Assuming this is the path to the generated service
import { HttpClient, HttpContext } from '@angular/common/http';
import { HttpRequestOptions, SKIP_OPTION } from '@seech/auth-ng';

@Injectable({
  providedIn: 'root',
})
export class AccountClientService {
  constructor(private http: HttpClient, private accountService: AccountService) { }

  /**
   * Initiates a login request.
   * @returns An Observable that emits a response indicating the success of the login operation.
   */
  login(option: HttpRequestOptions) {
    const context = (option.context ?? new HttpContext()).set(SKIP_OPTION.AUTHENTICATION, true).set(SKIP_OPTION.HEADERS, true);
    return this.http.get<User>('/api/Account/login', { headers: option.headers as any, context: context as any, observe: 'response' });
  }

  /**
   * Initiates a signup request.
   * @param User The user information to be used for signup.
   * @returns An Observable that emits a response indicating the success of the signup operation.
   */
  signup(body: User, option: HttpRequestOptions) {
    const context = (option.context ?? new HttpContext()).set(SKIP_OPTION.AUTHENTICATION, true).set(SKIP_OPTION.HEADERS, true);
    return this.http.post<User>('/api/Account/signup', body, { headers: option.headers as any, context: context as any, observe: 'response' });
  }

  // /**
  //  * Logs the user into the application.
  //  * @returns {Observable<Blob>} - The response from the login endpoint.
  //  */
  // login(): Observable<Blob> {
  //   return this.accountService.accountLogin('body');
  // }

  // /**
  //  * Signs up a new user.
  //  * @param user - The user object containing signup details.
  //  * @returns {Observable<Blob>} - The response from the signup endpoint.
  //  */
  // signup(user: User): Observable<Blob> {
  //   return this.accountService.accountSignup(user, 'body');
  // }
}
