/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';


export interface Subscription { 
    id: string;
    subscriptionKey: string;
    startDate: Date;
    endDate?: Date;
    status?: string;
    renewalDate?: Date;
    amount?: number;
    currency: string;
    isRefunded?: boolean;
    createdBy: string;
    createdByNavigation: User;
}

