import { NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AccountClientService, ApiModule, Configuration, SharedNgModule } from '@spectrum/shared-ng';
import { CoreNgModule } from '@seech/core-ng';
import { ACCOUNT_SERVICE_TOKEN, AuthNgModule } from '@seech/auth-ng';
import { ControlsNgModule } from '@seech/controls-ng';
import { IconsNgModule } from '@seech/icons-ng';
import { environment } from '../environments/environment';
import { AuthModule } from './auth/auth.module';
import { UxNgModule } from '@seech/ux-ng';
import { HttpProgressInterceptorService } from './interceptors/http-progress.interceptor';

const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpProgressInterceptorService, multi: true },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AuthModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    ApiModule.forRoot(() => new Configuration({ basePath: '' })),
    SharedNgModule,
    UxNgModule,
    IconsNgModule,

    CoreNgModule.forRoot({
      BASE_API_URL: environment.BASE_API_URL,
      REALTIME_HUB_URL: 'central-socket'
    }),

    ControlsNgModule.forRoot({
      googleAPIKey: environment.GOOGLE_API_KEY
    }),

    AuthNgModule.forRoot({
      AUTH_API_URL: environment.AUTH_API_URL,
      AUTH_WEB_URL: environment.AUTH_WEB_URL,
      BASE_API_URL: environment.BASE_API_URL,
      CLIENT_ID: environment.CLIENT_ID,
      SESSION_TIMEOUT: { INTERVAL: 300000, COUNTDOWN: 60000 },
    })
  ],
  exports: [ApiModule],
  providers: [
    { provide: ACCOUNT_SERVICE_TOKEN, useClass: AccountClientService },
    provideClientHydration(),
    provideAnimations(),
    ...HttpInterceptorProviders,

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
