/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserDomain { 
    id: string;
    url: string;
    status: string;
    reason?: string;
    isDeactivated?: boolean;
    createdBy: string;
    createdOn: Date;
}

