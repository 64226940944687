import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { SKIP_OPTION } from '@seech/auth-ng';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpProgressInterceptorService implements HttpInterceptor {
  private activeRequests: HttpRequest<any>[] = [];
  private isLoadingSubject = new BehaviorSubject<boolean>(false);

  // Expose loading state as an observable
  public readonly isLoading$ = this.isLoadingSubject.asObservable();

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Skip requests marked to bypass the progress indicator
    if (
      req.context.get(SKIP_OPTION.ALL) === true ||
      req.context.get(SKIP_OPTION.PROGRESS) === true
    ) {
      return next.handle(req);
    }

    // Add request to active queue
    this.addRequest(req);

    return next.handle(req).pipe(
      finalize(() => {
        // Remove request when complete
        this.removeRequest(req);
      })
    );
  }

  private addRequest(req: HttpRequest<any>) {
    this.activeRequests.push(req);
    if (this.activeRequests.length === 1) {
      this.isLoadingSubject.next(true); // Emit loading state as true
    }
  }

  private removeRequest(req: HttpRequest<any>) {
    const index = this.activeRequests.indexOf(req);
    if (index >= 0) {
      this.activeRequests.splice(index, 1);
    }

    if (this.activeRequests.length === 0) {
      this.isLoadingSubject.next(false); // Emit loading state as false
    }
  }
}