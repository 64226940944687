/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CoordinateSequenceFactory } from './coordinateSequenceFactory';
import { PrecisionModel } from './precisionModel';
import { NtsGeometryServices } from './ntsGeometryServices';


export interface GeometryFactory { 
    precisionModel?: PrecisionModel;
    coordinateSequenceFactory?: CoordinateSequenceFactory;
    srid: number;
    geometryServices?: NtsGeometryServices;
}

