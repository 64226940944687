/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RiskDetail } from './riskDetail';


export interface SafetyReportResponse { 
    domain: string;
    pagePath: string;
    safetyScore: number;
    riskLevel: string;
    lastUpdated: Date;
    risks: Array<RiskDetail>;
}

