/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type HttpStatusCode = 100 | 101 | 102 | 103 | 200 | 201 | 202 | 203 | 204 | 205 | 206 | 207 | 208 | 226 | 300 | 300 | 301 | 301 | 302 | 302 | 303 | 303 | 304 | 305 | 306 | 307 | 307 | 308 | 400 | 401 | 402 | 403 | 404 | 405 | 406 | 407 | 408 | 409 | 410 | 411 | 412 | 413 | 414 | 415 | 416 | 417 | 421 | 422 | 422 | 423 | 424 | 426 | 428 | 429 | 431 | 451 | 500 | 501 | 502 | 503 | 504 | 505 | 506 | 507 | 508 | 510 | 511;

export const HttpStatusCode = {
    NUMBER_100: 100 as HttpStatusCode,
    NUMBER_101: 101 as HttpStatusCode,
    NUMBER_102: 102 as HttpStatusCode,
    NUMBER_103: 103 as HttpStatusCode,
    NUMBER_200: 200 as HttpStatusCode,
    NUMBER_201: 201 as HttpStatusCode,
    NUMBER_202: 202 as HttpStatusCode,
    NUMBER_203: 203 as HttpStatusCode,
    NUMBER_204: 204 as HttpStatusCode,
    NUMBER_205: 205 as HttpStatusCode,
    NUMBER_206: 206 as HttpStatusCode,
    NUMBER_207: 207 as HttpStatusCode,
    NUMBER_208: 208 as HttpStatusCode,
    NUMBER_226: 226 as HttpStatusCode,
    NUMBER_300: 300 as HttpStatusCode,
    NUMBER_3002: 300 as HttpStatusCode,
    NUMBER_301: 301 as HttpStatusCode,
    NUMBER_3012: 301 as HttpStatusCode,
    NUMBER_302: 302 as HttpStatusCode,
    NUMBER_3022: 302 as HttpStatusCode,
    NUMBER_303: 303 as HttpStatusCode,
    NUMBER_3032: 303 as HttpStatusCode,
    NUMBER_304: 304 as HttpStatusCode,
    NUMBER_305: 305 as HttpStatusCode,
    NUMBER_306: 306 as HttpStatusCode,
    NUMBER_307: 307 as HttpStatusCode,
    NUMBER_3072: 307 as HttpStatusCode,
    NUMBER_308: 308 as HttpStatusCode,
    NUMBER_400: 400 as HttpStatusCode,
    NUMBER_401: 401 as HttpStatusCode,
    NUMBER_402: 402 as HttpStatusCode,
    NUMBER_403: 403 as HttpStatusCode,
    NUMBER_404: 404 as HttpStatusCode,
    NUMBER_405: 405 as HttpStatusCode,
    NUMBER_406: 406 as HttpStatusCode,
    NUMBER_407: 407 as HttpStatusCode,
    NUMBER_408: 408 as HttpStatusCode,
    NUMBER_409: 409 as HttpStatusCode,
    NUMBER_410: 410 as HttpStatusCode,
    NUMBER_411: 411 as HttpStatusCode,
    NUMBER_412: 412 as HttpStatusCode,
    NUMBER_413: 413 as HttpStatusCode,
    NUMBER_414: 414 as HttpStatusCode,
    NUMBER_415: 415 as HttpStatusCode,
    NUMBER_416: 416 as HttpStatusCode,
    NUMBER_417: 417 as HttpStatusCode,
    NUMBER_421: 421 as HttpStatusCode,
    NUMBER_422: 422 as HttpStatusCode,
    NUMBER_4222: 422 as HttpStatusCode,
    NUMBER_423: 423 as HttpStatusCode,
    NUMBER_424: 424 as HttpStatusCode,
    NUMBER_426: 426 as HttpStatusCode,
    NUMBER_428: 428 as HttpStatusCode,
    NUMBER_429: 429 as HttpStatusCode,
    NUMBER_431: 431 as HttpStatusCode,
    NUMBER_451: 451 as HttpStatusCode,
    NUMBER_500: 500 as HttpStatusCode,
    NUMBER_501: 501 as HttpStatusCode,
    NUMBER_502: 502 as HttpStatusCode,
    NUMBER_503: 503 as HttpStatusCode,
    NUMBER_504: 504 as HttpStatusCode,
    NUMBER_505: 505 as HttpStatusCode,
    NUMBER_506: 506 as HttpStatusCode,
    NUMBER_507: 507 as HttpStatusCode,
    NUMBER_508: 508 as HttpStatusCode,
    NUMBER_510: 510 as HttpStatusCode,
    NUMBER_511: 511 as HttpStatusCode
};

