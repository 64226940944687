/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RiskPreferenceItem } from './riskPreferenceItem';


export interface RiskPreferenceRequest { 
    preferences: Array<RiskPreferenceItem>;
}

