/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Stream } from './stream';


export interface FileResponse { 
    statusCode: number;
    headers: { [key: string]: Array<string>; };
    stream: Stream;
    isPartial: boolean;
}

