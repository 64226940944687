/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AnalysisResult } from './analysisResult';


export interface LegalReportAnalysis { 
    title?: string;
    isLegalDocument: boolean;
    analysisResult?: AnalysisResult;
    errorMessage: string;
}

